@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
  & .listingPageWrapper {
    background-color: var(--matterColorLight);
    padding: 0 24px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
  }
}

.imageWrapperForSectionImage {
  composes: heroHeight;
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  /* display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor); */
  /* z-index: 1; */
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  /* cursor: initial;

  border-radius: var(--borderRadius); */
}

.actionBarForProductLayout {
  /* margin: 24px 0px; */
  /* width: calc(100% - 48px); */

  /* @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  } */
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: marketplaceH4FontStyles from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: marketplaceH4FontStyles from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  border: none;
  padding: 8px 24px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-weight: var(--fontSemiBold);
  cursor: pointer;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin-right: 15px;

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    text-decoration: none;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainColumnForProductLayout {
  width: 100%;
  position: relative;
  @media (--viewportLarge) {
    max-width: 60%;
    width: 100%;
    position: sticky;
    top: 100px;
    height: fit-content;
  }
  & :global(.slick-slider) {
    position: relative;
  }
  & :global(.slick-dots) {
    position: relative;
    bottom: 0;
    max-width: 80%;
    margin: 0 auto;
    overflow: hidden;
    display: flex !important;

    @media (--viewportSmall) {
      align-items: center;
      justify-content: center;
    }
  }
  & :global(.slick-dots > li) {
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    @media (--viewportMedium) {
      width: 64px;
      min-width: 64px;
      min-height: 64px;
      height: 64px;
    }
  }
  & :global(.slick-dots > li > div) {
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: solid 1px var(--matterColorLight);
    @media (--viewportMedium) {
      border-radius: 10px;
      border: solid 2px var(--matterColorLight);
    }
  }
  & :global(.slick-dots > li.slick-active > div) {
    border: solid 1px var(--marketplaceColor);
    @media (--viewportMedium) {
      border: solid 2px var(--marketplaceColor);
    }
  }
  & :global(.slick-dots > li > div div) {
    height: 100%;
  }
  & :global(.slick-dots > li > div div picture) {
    height: 100%;
    display: flex;
  }
  & :global(.slick-arrow) {
    width: 30px;
    height: 30px;
    z-index: 1;
    top: auto;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (--viewportMedium) {
      width: 40px;
      height: 40px;
    }
  }
  & :global(.slick-arrow > svg) {
    border: solid 1px rgba(232, 232, 232, 1);
    border-radius: 100px;
    transition: var(--transitionStyle);
    width: 100%;
    height: 100%;
  }
  & :global(.slick-arrow:hover > svg) {
    transition: var(--transitionStyle);
    border-color: var(--marketplaceColor);
  }
  & :global(.slick-arrow:hover > svg > path) {
    stroke: var(--marketplaceColor);
    transition: var(--transitionStyle);
  }
  & :global(.slick-arrow::before) {
    content: '';
  }
  & :global(.slick-prev) {
    left: 0;
  }
  & :global(.slick-next) {
    right: 0;
  }
}

.orderColumnForProductLayout {
  width: 100%;
  margin-top: 20px;

  @media (--viewportLarge) {
    display: block;
    padding-left: 24px;
    margin-top: 0px;
    max-width: calc(100% - 60%);
  }
  & .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 12px;
    & > * {
      font-size: 14px;
      line-height: 100%;
      color: var(--matterColor);
    }
    & > a {
      color: var(--matterColorDark);
      transition: var(--transitionStyle);
      &:hover {
        color: var(--marketplaceColor);
        transition: var(--transitionStyle);
      }
    }
    & > span {
      color: var(--matterColorAnti);
    }
    & > svg {
      fill: none;
      width: 24px;
    }
  }
}

.contentWrapperForProductLayout {
  margin: 16px 0 56px 0;
  width: 100%;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    width: 100%;
    max-width: 1200px;
    margin: 16px auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    margin: 16px auto 117px auto;
  }

  & .soldBy {
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    font-size: 14px;
    background-color: var(--matterColorLight);
    border-radius: 6px;
    padding: 7px 10px;
    line-height: 100%;
    & > a {
      width: 50px;
      height: 50px;
      max-width: 100%;
      margin-right: 15px;
    }
  }
  & .soldByMobile {
    position: relative;
    top: 6px;
    z-index: 999;
    background-color: var(--matterColorLight);
    border-radius: 6px;
    padding: 3px 10px;
    width: fit-content;
    left: 6px;
    font-size: 12px;
  }
}

.mainContentWrapper {
  flex-direction: column;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
  }
}

.messageSec {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  & > span {
    margin-right: 20px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
    & > svg {
      width: 24px;
    }
  }
  & .likes {
    padding-left: 20px;
    margin-left: 10px;
    border-left: solid 1px var(--matterColorNegative);
    @media (--viewportSmall) {
      padding-left: 30px;
      margin-left: 20px;
    }
  }
}
.gallarySection {
  &.gallarySlider {
    & :global(.slick-dots.slick-thumb) {
      & img {
        border-style: none;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    & :global(.slick-list) {
      border-radius: 8px;
      margin-bottom: 10px;
      border: solid 1px rgba(232, 232, 232, 1);
    }
    & .sliderItem {
      height: 100%;
      width: 100%;
      & > div {
        position: relative;
        padding-bottom: 100%;
        height: 100%;
        width: 100%;
        & > img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: contain;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
.trustedBuyerProtection {
  margin-top: 20px;
  border: solid 1px #e7e7e7;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;

  &.fixWidth {
    @media (--viewportLarge) {
      /* width: calc(100% - 100px); */
      /* float: right; */
    }
  }

  & .securityIcon {
    width: 24px;

    & > svg {
      fill: var(--marketplaceColor);
    }
  }

  & .securityInfo {
    width: calc(100% - 24px);
    padding-left: 15px;

    & > h2 {
      font-size: 16px;
      color: var(--marketplaceColor);
      margin: 0 0 5px 0;
      line-height: 100%;
    }

    & > p {
      font-size: 14px;
      line-height: 120%;
      color: var(--matterColor);
      margin: 0px;
    }
  }
}

.moreItemSeller {
  margin-top: 100px;
  float: left;
  width: 100%;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
}

.tags {
  display: block;
  cursor: pointer;
  float: left;
  width: 100%;
  line-height: 110%;
  margin-bottom: 24px;

  & .tag {
    display: inline-block;
    margin-right: 5px;
    font-size: 14px;
    color: var(--marketplaceColor);
    font-weight: 700;
    line-height: 120%;

    &:hover {
      color: var(--matterColordark);
      text-decoration: underline;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
.aboutBrand {
  border-top: rgba(232, 232, 232, 1) solid 1px;
  padding-top: 24px;
  float: left;
  width: 100%;

  & > h2 {
    color: rgba(28, 28, 28, 1);
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px 0;
    font-weight: var(--fontWeightSemiBold);
  }
  & > p {
    color: rgba(88, 87, 87, 1);
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  & .aboutDelivery {
    margin-top: 24px;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    @media (--viewportMedium) {
      grid-gap: 8px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      overflow-x: initial;
    }
    & .deliverInfo {
      background-color: rgba(247, 247, 247, 1);
      padding: 24px 16px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: calc(100% / 1);
      min-width: calc(100% / 1);
      margin-right: 20px;
      scroll-snap-align: center;
      @media (--viewportSmall) {
        width: calc(100% / 2);
        min-width: calc(100% / 2);
      }
      @media (--viewportMedium) {
        width: 100%;
        min-width: auto;
        margin-right: 0px;
      }
      &:last-child {
        margin-right: 0;
      }
      & > svg {
        margin-bottom: 12px;
        & path {
          fill: rgba(150, 150, 150, 1);
        }
      }
      & > h2 {
        color: rgba(28, 28, 28, 1);
        font-weight: var(--fontWeightSemiBold);
        font-size: 13px;
        line-height: 20px;
        margin: 0 0 4px 0;
      }
      & > p {
        color: rgba(88, 87, 87, 1);
        font-size: 13px;
        line-height: 20px;
        margin: 0;
      }
    }
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left: 1px solid var(--matterColorNegative);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  @media (--viewportLarge) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  margin-bottom: 14px;
  float: left;
  width: 100%;
  @media (--viewportLarge) {
    display: block;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: marketplaceH5FontStyles from global;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: marketplaceH5FontStyles from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--marketplaceColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  composes: marketplaceLinkStyles from global;
  composes: marketplaceH5FontStyles from global;
  margin: 0;
}

.productDetails {
  margin-bottom: 16px;

  & .sectionDescription {
    padding: 0px;
    margin: 0 0 10px 0;
    white-space: normal;
    word-wrap: break-word;
  }

  & .priceBox {
    margin: 0 0 24px 0;
    display: flex;
    align-items: center;

    & .price {
      margin: 0;
      color: var(--matterColorDark);
      font-size: 24px;
      line-height: 32px;
      font-weight: var(--fontWeightSemiBold);
    }
    & .shippingPrice {
      color: var(--matterColor);
      font-size: 13px;
      margin: 0 0 0 10px;
    }
  }
  & .description {
    font-size: 16px;
    line-height: 24px;
    color: rgba(88, 87, 87, 1);
    margin: 0 0 24px 0;
    white-space: normal;
    word-wrap: break-word;
  }
}

.sectionTitle {
  /* Font for sections titles */
  composes: marketplaceH3FontStyles from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;
  font-size: 18px;
}

.detailsTitle,
.descriptionTitle {
  margin-bottom: 10px !important;
  margin: 0 0 24px 0;
  font-size: 26px;
  line-height: 40px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  @media (--viewportMedium) {
    font-size: 26px;
    line-height: 48px;
  }

  & .authorName {
    & > span {
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.sectionDetails {
  padding: 0px;
  float: left;
  width: 100%;
  margin-bottom: 24px;

  & .details {
    margin: 0;
    /* border: solid 1px rgba(232, 232, 232, 1); */
    border-radius: 10px;

    & .detailsRow {
      display: inline-flex;
      width: 100%;
      padding: 10px 0px;
      line-height: 36px;
      list-style: none;
      font-weight: var(--fontWeightRegular);
      border-bottom: solid 1px rgba(232, 232, 232, 1);
      &:last-child {
        border-bottom: none;
      }
      & > span {
        width: 50%;
        font-size: 16px;
        line-height: 24px;
        color: rgba(88, 87, 87, 1);

        @media (--viewportLarge) {
          width: 80%;
        }

        &.detailLabel {
          width: 50%;
          color: rgba(150, 150, 150, 1);
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.09px;

          @media (--viewportLarge) {
            width: 40%;
          }
        }
      }
    }
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.sectionReviews {
  padding: 0px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 5px;
    margin-bottom: 0;
  }
}

.reviewsTitle {
  composes: sectionTitle;
  margin: 24px 0;
}

.sectionAuthor {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.aboutProviderTitle {
  composes: sectionTitle;
  float: left;
  width: 100%;
  margin: 0px 0 23px 0;

  @media (--viewportMedium) {
    margin: 0px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 0px 0 24px 0;
    padding: 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0;

  @media (--viewportLarge) {
    margin: 0px 0 28px 0;
    padding-bottom: 4px;
  }
}

.productMobileHeading {
  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.addToCart {
  margin-top: 20px;
  display: none;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    /* padding-left: calc(100% - 450px); */
  }

  & .addBtn {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    border: none;
    padding: 8px 24px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    font-weight: var(--fontSemiBold);
    cursor: pointer;

    &:hover {
      background-color: var(--matterColorDark);
      color: var(--matterColorLight);
    }

    & > svg {
      margin-right: 15px;
    }
  }
}
.stock {
  color: var(--marketplaceColor);
}

.editAction {
  margin-bottom: 24px;

  & > a {
    & > button {
      font-size: 14px;
      line-height: 120%;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.content {
  @media (--viewportMedium) {
    margin: 32px auto;
    width: 100%;
    max-width: 650px;
    background-color: #fff;
    padding: 60px 40px;
    text-align: center;
  }

  & .paypalHead {
    & > img {
      width: 160px;
    }
  }

  & .title {
    hyphens: auto;
    margin-top: 40px;
    font-size: 26px;
    margin-bottom: 10px;
    color: var(--matterColorDark);
  }

  & > p {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--mattercolorNeg);
  }

  & > button {
    margin: 20px auto;
    display: inline-block;
  }
}

.text {
  margin-right: 6px;
}

.socialSharing {
}

.socialSharing {
  & > svg {
    fill: none;
  }
}

.supportLink {
  margin-left: 4px;
}

.mobileOrderColumn {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

.cartMessage {
  display: flex;
  background: #f1d9e6;
  width: fit-content;
  padding: 2px 14px;
  border-radius: 14px;
  margin-bottom: 10px;
  & p {
    margin: 0;
    color: black;
    font-size: 12px;
  }
  & svg {
    width: 16px;
    margin-right: 10px;
  }
}

.outOfStock {
  margin: 12px 4px;
  & button {
    width: 100%;
  }
}
.selectSize {
  margin-bottom: 16px;
  display: grid;
  flex-wrap: nowrap;
  grid-gap: 15px;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  & > select {
    padding: 10px 20px;
    border-radius: 6px;
    color: var(--matterColor);
  }
}

.sizePill {
  border: 1px solid #dedede;
  padding: 3px 5px;
  border-radius: 5px;
  background: white;
  font-size: 13px;
  text-wrap: nowrap;
  cursor: pointer;
  text-align: center;
}

.selectedSize {
  border: 1px solid #c14185;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 13px;
  text-wrap: nowrap;
  cursor: pointer;
  text-align: center;
  background: #c14185 !important;
  color: white !important;
}

.singleVariant {
  margin-bottom: 10px;

  & > span {
    font-size: 13px;
    font-weight: 700;
    color: #c6488c;
  }
}
