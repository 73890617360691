@import '../../../styles/customMediaQueries.css';

.root {
  & > svg {
    fill: none;
    width: 74px;
    height: 74px;
    margin-bottom: 20px;
    & path,
    & rect {
      stroke: var(--marketplaceColor);
      stroke-width: 1.2;
    }
  }
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColorDark);
  margin-bottom: 14px;
}

.field {
  margin: 25px 0 0px 0;

  @media (--viewportMedium) {
    margin: 22px 0 0px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
.submitBtn {
  margin-top: 0;
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  width: 100%;
}
.actionBtns {
  margin-top: 24px;
}
